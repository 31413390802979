import React, { useCallback, useEffect } from "react";

const isDarkMode = () => {
  return localStorage.theme === 'dark' ||
      (!('theme' in localStorage) && window.matchMedia('(prefers-color-scheme: dark)').matches)
};

const ThemeSwitch = () => {
  const toggleLightMode = useCallback(() => {
    if (isDarkMode()) {
      localStorage.setItem('theme', 'light');
      document.documentElement.classList.remove('dark');
    } else {
      localStorage.setItem('theme', 'dark');
      document.documentElement.classList.add('dark');
    }
  }, []);

  const updateTheme = useCallback(() => {
    if (isDarkMode()) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, []);

  useEffect(() => {
    updateTheme();
    window.addEventListener('storage', updateTheme);

    return () => {
      window.removeEventListener('storage', updateTheme);
    };
  }, []);

  return (
    <div className="flex items-center">
      <button
        type="button"
        className={`relative inline-flex flex-shrink-0 h-6 transition-all duration-200 ease-in-out border border-gray-100 rounded-full cursor-pointer w-11 focus:outline-none bg-gray-100 dark:bg-secondary-500`}
        role="switch"
        aria-checked="false"
        onClick={toggleLightMode}
      >
        <span
          class="relative inline-block w-5 h-5 my-px transition duration-200 ease-in-out transform bg-white rounded-full shadow pointer-events-none ring-0 dark:translate-x-5">
          <span
            class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity duration-200 ease-in opacity-100 dark:opacity-0 dark:ease-out dark:duration-100"
            aria-hidden="true">
            <svg class="w-4 h-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path fill-rule="evenodd"
                d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                clip-rule="evenodd" />
            </svg>
          </span>
          <span
            class="absolute inset-0 flex items-center justify-center w-full h-full transition-opacity duration-100 ease-out opacity-0 dark:opacity-100 dark:ease-in dark:duration-200"
            aria-hidden="true">
            <svg class="w-4 h-4 text-primary-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
              fill="currentColor">
              <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
            </svg>
          </span>
        </span>
      </button>

      <span className="ml-4" id="data-saver-off">
        <span className="sr-only">
          Dark Mode
        </span>
      </span>
    </div>
  );
};

export default ThemeSwitch;
