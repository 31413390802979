import { useStaticQuery, graphql } from 'gatsby';

const useMetaImage = () => {
  const { site: { siteMetadata: { siteUrl } }, allFile: { nodes: [{ publicURL }] } } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }

        allFile(filter: {relativePath: {eq: "starbeat-app.jpg"}}) {
          nodes {
            publicURL
          }
        }
      }
    `,
  );

  return `${siteUrl}${publicURL}`;
};

export default useMetaImage;
