import React from 'react';
import { Link } from 'gatsby';
import Logo from '../images/logo.svg';
import LogoDark from '../images/logo-dark.svg';

const downloadApp = () => {
  const userAgent = (navigator?.userAgent || "").toLowerCase();

  if (userAgent.indexOf("android") > -1) {
    window.location.href = "https://play.google.com";
  } else if (userAgent.indexOf("iphone") > -1) {
    window.location.href = "https://apps.apple.com/in/app/starbeat/id1448350462";
  } else {
    window.location.href = '/fans#download-app';
  }
}

const Footer = () => (
  <footer className="py-12 bg-white dark:bg-gray-900 sm:py-16 lg:py-20">
    <div className="px-6 mx-auto max-w-7xl sm:px-12 lg:px-16 xl:px-20">
      <div className="grid grid-cols-2 lg:grid-cols-5 gap-x-8 gap-y-16">
        <div className="col-span-2 lg:pr-16">
          <img className="block w-auto h-8 dark:hidden" src={Logo} alt="Starbeat Logo" />
          <img className="hidden w-auto h-8 dark:block" src={LogoDark} alt="Starbeat Logo" />

          <p className="mt-6 text-base font-medium text-gray-500 dark:text-gray-200">
            STARBEAT is an ethical approach to social media & creator monetization
          </p>

          <ul className="flex items-center mt-6 space-x-5">
            <li>
              <a href="https://www.facebook.com/StarBeatApp/" title="Starbeat Facebook page" target="_blank" rel="noopener"
                className="inline-flex items-center justify-center w-10 h-10 transition-all duration-200 bg-white border border-gray-100 rounded-full shadow-lg dark:border-gray-600 dark:hover:border-primary-400 hover:border-primary-500 hover:bg-primary-500 hover:text-white text-primary-500 dark:bg-gray-800 dark:text-primary-300 dark:hover:bg-primary-400 dark:hover:text-white">
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    d="M12.001 2.002c-5.522 0-9.999 4.477-9.999 9.999 0 4.99 3.656 9.126 8.437 9.879v-6.988h-2.54v-2.891h2.54V9.798c0-2.508 1.493-3.891 3.776-3.891 1.094 0 2.24.195 2.24.195v2.459h-1.264c-1.24 0-1.628.772-1.628 1.563v1.875h2.771l-.443 2.891h-2.328v6.988C18.344 21.129 22 16.992 22 12.001c0-5.522-4.477-9.999-9.999-9.999z">
                  </path>
                </svg>
              </a>
            </li>

            <li>
              <a href="#" title="" target="_blank" rel="noopener"
                className="inline-flex items-center justify-center w-10 h-10 transition-all duration-200 bg-white border border-gray-100 rounded-full shadow-lg dark:border-gray-600 dark:hover:border-primary-400 hover:border-primary-500 hover:bg-primary-500 hover:text-white text-primary-500 dark:bg-gray-800 dark:text-primary-300 dark:hover:bg-primary-400 dark:hover:text-white">
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    d="M19.633 7.997c.013.175.013.349.013.523 0 5.325-4.053 11.461-11.46 11.461-2.282 0-4.402-.661-6.186-1.809.324.037.636.05.973.05a8.07 8.07 0 0 0 5.001-1.721 4.036 4.036 0 0 1-3.767-2.793c.249.037.499.062.761.062.361 0 .724-.05 1.061-.137a4.027 4.027 0 0 1-3.23-3.953v-.05c.537.299 1.16.486 1.82.511a4.022 4.022 0 0 1-1.796-3.354c0-.748.199-1.434.548-2.032a11.457 11.457 0 0 0 8.306 4.215c-.062-.3-.1-.611-.1-.923a4.026 4.026 0 0 1 4.028-4.028c1.16 0 2.207.486 2.943 1.272a7.957 7.957 0 0 0 2.556-.973 4.02 4.02 0 0 1-1.771 2.22 8.073 8.073 0 0 0 2.319-.624 8.645 8.645 0 0 1-2.019 2.083z">
                  </path>
                </svg>
              </a>
            </li>

            <li>
              <a href="https://instagram.com/starbeat.app" title="Starbeat Instagram page" target="_blank" rel="noopener"
                className="inline-flex items-center justify-center w-10 h-10 transition-all duration-200 bg-white border border-gray-100 rounded-full shadow-lg dark:border-gray-600 dark:hover:border-primary-400 hover:border-primary-500 hover:bg-primary-500 hover:text-white text-primary-500 dark:bg-gray-800 dark:text-primary-300 dark:hover:bg-primary-400 dark:hover:text-white">
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    d="M11.999 7.377a4.623 4.623 0 1 0 0 9.248 4.623 4.623 0 0 0 0-9.248zm0 7.627a3.004 3.004 0 1 1 0-6.008 3.004 3.004 0 0 1 0 6.008z">
                  </path>
                  <circle cx="16.806" cy="7.207" r="1.078"></circle>
                  <path
                    d="M20.533 6.111A4.605 4.605 0 0 0 17.9 3.479a6.606 6.606 0 0 0-2.186-.42c-.963-.042-1.268-.054-3.71-.054s-2.755 0-3.71.054a6.554 6.554 0 0 0-2.184.42 4.6 4.6 0 0 0-2.633 2.632 6.585 6.585 0 0 0-.419 2.186c-.043.962-.056 1.267-.056 3.71 0 2.442 0 2.753.056 3.71.015.748.156 1.486.419 2.187a4.61 4.61 0 0 0 2.634 2.632 6.584 6.584 0 0 0 2.185.45c.963.042 1.268.055 3.71.055s2.755 0 3.71-.055a6.615 6.615 0 0 0 2.186-.419 4.613 4.613 0 0 0 2.633-2.633c.263-.7.404-1.438.419-2.186.043-.962.056-1.267.056-3.71s0-2.753-.056-3.71a6.581 6.581 0 0 0-.421-2.217zm-1.218 9.532a5.043 5.043 0 0 1-.311 1.688 2.987 2.987 0 0 1-1.712 1.711 4.985 4.985 0 0 1-1.67.311c-.95.044-1.218.055-3.654.055-2.438 0-2.687 0-3.655-.055a4.96 4.96 0 0 1-1.669-.311 2.985 2.985 0 0 1-1.719-1.711 5.08 5.08 0 0 1-.311-1.669c-.043-.95-.053-1.218-.053-3.654 0-2.437 0-2.686.053-3.655a5.038 5.038 0 0 1 .311-1.687c.305-.789.93-1.41 1.719-1.712a5.01 5.01 0 0 1 1.669-.311c.951-.043 1.218-.055 3.655-.055s2.687 0 3.654.055a4.96 4.96 0 0 1 1.67.311 2.991 2.991 0 0 1 1.712 1.712 5.08 5.08 0 0 1 .311 1.669c.043.951.054 1.218.054 3.655 0 2.436 0 2.698-.043 3.654h-.011z">
                  </path>
                </svg>
              </a>
            </li>

            <li>
              <a href="https://www.linkedin.com/company/starbeat" title="Starbeat LinkedIn page" target="_blank" rel="noopener"
                className="inline-flex items-center justify-center w-10 h-10 transition-all duration-200 bg-white border border-gray-100 rounded-full shadow-lg dark:border-gray-600 dark:hover:border-primary-400 hover:border-primary-500 hover:bg-primary-500 hover:text-white text-primary-500 dark:bg-gray-800 dark:text-primary-300 dark:hover:bg-primary-400 dark:hover:text-white">
                <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path
                    d="M20 3H4a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM8.339 18.337H5.667v-8.59h2.672v8.59zM7.003 8.574a1.548 1.548 0 1 1 0-3.096 1.548 1.548 0 0 1 0 3.096zm11.335 9.763h-2.669V14.16c0-.996-.018-2.277-1.388-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248h-2.667v-8.59h2.56v1.174h.037c.355-.675 1.227-1.387 2.524-1.387 2.704 0 3.203 1.778 3.203 4.092v4.71z">
                  </path>
                </svg>
              </a>
            </li>
          </ul>

          <p className="mt-8 text-sm font-medium text-gray-400 dark:text-gray-500">
            © 2022 Starbeat - All Rights Reserved
          </p>
        </div>

        <div>
          <p className="text-lg font-bold text-gray-500 dark:text-gray-200">
            Product
          </p>

          <ul className="mt-6 space-y-4">
            <li>
              <Link to='https://tally.so/r/3NkpQw' title="Download Starbeat app"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Download
              </Link>
            </li>

            <li>
              <Link to='/creators' title="Creators"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Creators
              </Link>
            </li>

            <li>
              <Link to='/fans' title="Fans"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Fans
              </Link>
            </li>

            <li>
              <Link to='/creators#pricing' title="Pricing"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Pricing & Plans
              </Link>
            </li>

            <li>
              <Link to='/comparison/?compare-with=social-media' title="YouTube Comparison"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Starbeat vs Social Media
              </Link>
            </li>

            <li>
              <Link to='/comparison/?compare-with=creator-monetization' title="Patreon Comparison"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Starbeat vs Creator Monetization Tools
              </Link>
            </li>
          </ul>
        </div>

        <div>
          <p className="text-lg font-bold text-gray-500 dark:text-gray-200">
            Resources
          </p>

          <ul className="mt-6 space-y-4">
            <li>
              <Link to='/creators' title="Creators"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Creator Guide ↗
              </Link>
            </li>

            <li>
              <Link to='/creators#faq' title="FAQ"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                FAQs ↗
              </Link>
            </li>

            <li>
              <a href="https://tally.so/r/3NkpQw" title="Newsletter"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Newsletter
              </a>
            </li>

            <li>
              <a href="mailto:hello@starbeat.com" title="Support"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                24x7 Support
              </a>
            </li>
          </ul>
        </div>

        <div className="col-span-2 lg:col-span-1">
          <p className="text-lg font-bold text-gray-500 dark:text-gray-200">
            Company
          </p>

          <ul className="mt-6 space-y-4">
            <li>
              <a href="#" title=""
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                About Us ↗
              </a>
            </li>

            <li>
              <a href="https://tally.so/r/mebGQ3" title="Investor Relations"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Investor Relations ↗
              </a>
            </li>

            <li>
              <a href="https://tally.so/r/mZByym" title="Careers" target="_blank" rel="noopener"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Career (We’re Hiring!) ↗
              </a>
            </li>

            <li>
              <a href="#" title=""
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Terms of Service ↗
              </a>
            </li>

            <li>
              <Link to='/privacy' title="Privacy Policy"
                className="inline-flex text-base font-medium text-gray-700 transition-all duration-300 hover:text-secondary-500 dark:text-gray-300 dark:hover:text-secondary-400 hover:translate-x-1">
                Privacy Policy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
