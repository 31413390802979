import * as React from "react"
import { Link } from "gatsby";

import ThemeSwitch from "./themeSwitch";
import Logo from './../images/logo.svg';
import LogoDark from './../images/logo-dark.svg';

const Header = () => (
  <header className="py-8 bg-white dark:bg-gray-900">
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-24 xl:px-28">
      <div className="flex items-center justify-center md:justify-between">
        <div className="flex-shrink-0">
          <Link to='/' className="flex">
            <img className="block w-auto h-10 dark:hidden" src={Logo} alt="Starbeat Logo" />
            <img className="hidden w-auto h-10 dark:block" src={LogoDark} alt="Starbeat Logo" />
          </Link>
        </div>

        <div className="hidden md:flex md:items-center md:justify-end md:space-x-8">
          <Link to='/'
            className="text-base font-semibold text-gray-500 transition-all duration-200 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
            Home
          </Link>

          <Link
            to='/#early-access'
            className="inline-flex items-center justify-center px-4 py-2 text-base font-semibold text-white transition-all duration-200 border border-transparent rounded-lg shadow-sm bg-primary-500 hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 dark:bg-primary-400 dark:hover:bg-primary-500 dark:focus:ring-primary-400"
            role="button">
            Get early access
          </Link>

          <ThemeSwitch />
        </div>
      </div>
    </div>
  </header>
);

export default Header;
