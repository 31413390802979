import React from 'react';
import Helmet from 'react-helmet';

import useMetaImage from '../hooks/useMetaImage';

const SuperHeader = ({ title, metaTitle, metaDescription, currentUrl, ...otherProps }) => {
  const metaImageUrl = useMetaImage();

  return (
    <Helmet
      bodyAttributes={{ class: 'overflow-x-hidden font-sans antialiased text-gray-900 bg-white dark:text-white dark:bg-gray-900' }}
      {...otherProps}
    >
      <title>{title}</title>

      <meta name="description" content={metaDescription} />
      <meta property="og:description" content={metaDescription} />
      <meta property="twitter:description" content={metaDescription} />

      <meta property="og:title" content={metaTitle} />
      <meta property="twitter:title" content={metaTitle} />
      
      <meta property="og:image" itemProp="image" content={metaImageUrl} />
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={currentUrl} />
      
      <meta property="twitter:image" itemProp="image"  content={metaImageUrl} />
      <meta property="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

export default SuperHeader;
